import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '@/components/icons/CloseIcon';
import { ActionButton } from '@/components/icons/ActionButton';

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at calc(100% - 1px) 1px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: "circle(30px at calc(100% - 1px) 1px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40,
        },
    },
};

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

const items = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const SwitchLanguage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const languages = [
        { value: 'en', label: 'ENGLISH' },
        { value: 'es', label: 'ESPAÑOL' },
        { value: 'th', label: 'THAI' },
        { value: 'jp', label: 'JAPANESE' },
    ];

    const changeLanguage = (language) => {
        i18n.changeLanguage(language.value);
        setSelectedLanguage(language.value);
        setIsOpen(false);
    };

    const handleLanguageMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleCloseLanguage = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            <ActionButton
                onClick={handleLanguageMenu}
                ariaLabel={`Change language. Current language: ${selectedLanguage.toUpperCase()}`}
            >
                <p className="text-sm font-medium w-6 h-6 leading-relaxed">
                    {selectedLanguage.toUpperCase()}
                </p>
            </ActionButton>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial="closed"
                        exit="closed"
                        animate={isOpen ? "open" : "closed"}
                        variants={sidebar}
                        transition={{ duration: 0.3 }}
                        className="fixed inset-0 z-[100] flex flex-col w-screen h-screen bg-zircon/80 dark:bg-vulcan/80 backdrop-blur-xl"
                    >
                        <div className="absolute flex end-2 z-50 m-2">
                            <ActionButton onClick={handleCloseLanguage} ariaLabel="Close Language Menu">
                                <CloseIcon className="h-6 w-6" />
                            </ActionButton>
                        </div>

                        <motion.ul
                            variants={list}
                            className="absolute inset-0 flex flex-col items-center justify-center h-full gap-6"
                        >
                            {languages.map((language) => (
                                <motion.li
                                    key={language.value}
                                    variants={items}
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="cursor-pointer"
                                >
                                    <p
                                        onClick={() => changeLanguage(language)}
                                        className={`text-3xl transition duration-300 ${selectedLanguage === language.value ? 'font-semibold' : 'font-medium text-manatee/90 dark:text-jumbo/90'}`}
                                    >
                                        {language.label}
                                    </p>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
