import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialNetworks } from '@/components/SocialNetworks';

export const Profile = () => {
    const { t } = useTranslation();

    return (
        <section className='flex dark:bg-cinder bg-mischka/25 rounded-3xl w-full min-h-[28rem]'>
            <div className='flex flex-col justify-between p-9 w-full md:w-1/2 h-auto'>
                <div className='space-y-6'>
                    <h1 className='text-4xl text-woodsmoke dark:text-alabaster font-medium leading-tight'>
                        {t(`profile.title`)}
                    </h1>
                    <p className='text-base leading-relaxed opacity-80'>
                        {t(`profile.desc`)}
                    </p>
                </div>
                <div className='flex justify-center md:justify-start'>
                    <SocialNetworks />
                </div>
            </div>
        </section>
    );
};
