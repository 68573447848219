import React from 'react';
import { LinkedinIcon } from '@/components/icons/LinkedinIcon';
import { GithubIcon } from '@/components/icons/GithubIcon';
import { MediumIcon } from '@/components/icons/MediumIcon';
import { DribbbleIcon } from '@/components/icons/DribbbleIcon';
import { CalendarIcon } from '@/components/icons/CalendarIcon';

const socialLinks = [
    { name: "linkedin", url: "https://www.linkedin.com/in/zanozano", icon: <LinkedinIcon className="h-6 w-6 sm:h-8 sm:w-8" /> },
    { name: "github", url: "https://github.com/zanozano", icon: <GithubIcon className="h-6 w-6 sm:h-8 sm:w-8" /> },
    { name: "medium", url: "https://medium.com/@zanozanozano", icon: <MediumIcon className="h-6 w-6 sm:h-8 sm:w-8" /> },
    { name: "dribbble", url: "https://dribbble.com/zanozanozano", icon: <DribbbleIcon className="h-6 w-6 sm:h-8 sm:w-8" /> },
    { name: "calendly", url: "https://calendly.com/zanozanozano/15min", icon: <CalendarIcon className="h-6 w-6 sm:h-8 sm:w-8" /> },
];

export const SocialNetworks = () => {
    return (
        <div className="flex justify-center gap-3 sm:gap-6 mt-11">
            {socialLinks.map(({ name, url, icon }) => (
                <a
                    key={name}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${name} flex items-center p-2 sm:p-3 rounded-xl border border-mirage/20 dark:border-mirage/80 transition duration-500`}
                    aria-label={`Visit ${name} profile`}
                >
                    {icon}
                </a>
            ))}
        </div>
    );
};
