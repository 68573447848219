import { DevTemplate } from "@/pages/template/DevTemplate";

export const devProjects = [
    {
        id: 1,
        name: 'PokeQuest',
        desc: 'Interactive platform for Pokémon enthusiasts to dynamically search and explore detailed data about their favorite creatures, designed for an immersive experience',
        pathname: 'pokequest',
        featured: '',
        keywords: 'Pokédex Interface Design, Interactive Pokémon Search, Dynamic UX for Pokémon Data, Frontend Development for Pokémon Platform',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 2,
        name: 'ZenTask',
        desc: 'Streamlined task management solution designed to keep you organized and efficiently handle your tasks with ease and precision',
        pathname: 'zentask',
        featured: '',
        keywords: 'Task Management, User Experience, Design Tools, Workflow Efficiency',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 3,
        name: 'PokeFinder',
        desc: 'Dynamic tool that elevates Pokémon hunting. Easily explore and find Pokémon in your surroundings with excitement and convenience',
        pathname: 'pokefinder',
        featured: '',
        keywords: 'Pokémon hunting, interactive tool, Pokémon exploration, user interface design',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 4,
        name: 'Mathly',
        desc: 'User-friendly tool that simplifies mathematical tasks and improves your calculation experience, making math easier and more efficient',
        pathname: 'mathly',
        featured: '',
        keywords: 'mathematical tool, calculation software, math efficiency, user-friendly design',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 5,
        name: 'SplitWise',
        desc: 'Web app designed to manage and split bills, making expense sharing and financial harmony among roommates easy and efficient',
        pathname: 'splitwise',
        featured: '',
        keywords: 'Expense Sharing, Bill Management, Financial Harmony, Roommate Budgeting',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 6,
        name: 'EpicHero',
        desc: 'Web app for superhero fans to search and view detailed profiles of their favorite heroes, providing comprehensive insights and information',
        pathname: 'epichero',
        featured: '',
        keywords: 'superhero search, detailed profiles, web app, superhero information',
        element: (props) => <DevTemplate {...props} />,
        active: false
    },
    {
        id: 7,
        name: 'ZooTune',
        desc: 'Web app platform for managing and exploring animal data. Browse interactive cards with unique sounds and detailed profiles for each creature',
        pathname: 'zootune',
        featured: '',
        keywords: 'web app, animal data management, interactive cards, creature profiles',
        element: (props) => <DevTemplate {...props} />,
        active: false
    }
];
