import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@/hooks/useForm';

export const ContactForm = () => {
    const { t } = useTranslation();
    const { formData, errors, handleInputChange, handleFormSubmit, isSubmitting } = useForm();
    const messageLength = formData.message.length;

    return (
        <form onSubmit={handleFormSubmit} className="space-y-8 w-full md:w-1/2">
            <label htmlFor="name" className="flex flex-col gap-2 font-regular">
                {t('contact.name')}*
                <input
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`min-h-12 px-4 bg-alabaster/50 dark:bg-bunker/50 rounded-md border font-normal focus:outline-none focus:border-primary dark:focus:border-secondary ${errors.name ? 'border-thunderbird' : 'border-mirage'}`}
                    placeholder={t('contact.namePlaceholder')}
                />
                {errors.name && (
                    <p className="text-thunderbird text-sm font-normal">
                        {t('contact.nameError')}
                    </p>
                )}
            </label>

            <label htmlFor="email" className="flex flex-col gap-2 font-regular">
                {t('contact.email')}*
                <input
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={`min-h-12 px-4 rounded-md border font-normal bg-alabaster/50 dark:bg-bunker/50 focus:outline-none focus:border-primary dark:focus:border-secondary ${errors.email ? 'border-thunderbird' : 'border-mirage'}`}
                    placeholder={t('contact.emailPlaceholder')}
                />
                {errors.email && (
                    <p className="text-thunderbird text-sm font-normal">
                        {t('contact.emailError')}
                    </p>
                )}
            </label>

            <label htmlFor="message" className="flex flex-col gap-2 font-regular">
                {t('contact.message')}*
                <textarea
                    id="message"
                    name="message"
                    autoComplete="off"
                    value={formData.message}
                    onChange={handleInputChange}
                    className={`min-h-12 p-4 resize-none rounded-md bg-alabaster/50 dark:bg-bunker/50 border font-normal focus:outline-none focus:border-primary dark:focus:border-secondary ${errors.message ? 'border-thunderbird' : 'border-mirage'}`}
                    placeholder={t('contact.messagePlaceholder')}
                    rows="4"
                    maxLength="255"
                />
                <span className="flex justify-between">
                    {errors.message && (
                        <p className="text-thunderbird text-sm font-normal">
                            {t('contact.messageError')}
                        </p>
                    )}
                    <p className="text-sm">{messageLength}/255</p>
                </span>
            </label>

            <div className="flex items-center justify-end">
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full min-h-12 py-3 rounded-md transition duration-300 ${isSubmitting ? 'bg-gray-400 text-gray-300 cursor-not-allowed' : 'bg-primary hover:brightness-125 text-alabaster'
                        }`}
                >
                    <p className='text-sm font-medium'>
                        {t('contact.submit')}
                    </p>
                </button>
            </div>
        </form>
    );
};
