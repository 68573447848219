import React from 'react';

export const ActionButton = ({ children, onClick, ariaLabel }) => {
    return (
        <button
            onClick={onClick}
            aria-label={ariaLabel}
            className='flex items-center justify-center z-50 p-2 rounded-full cursor-pointer bg-mischka/30 dark:bg-shark/90 hover:scale-105 transition-all duration-300 hover:brightness-90 dark:hover:brightness-125'
        >
            {children}
        </button>
    );
};

