import { UxuiTemplate } from '@/pages/template/UxuiTemplate';

export const uxuiProjects = [
    {
        id: 1,
        name: 'Moya Platform',
        desc: 'Innovative web platform for automating container registration, designed to streamline customs management with user-friendly and efficient features',
        featured: '/images/projects/moya/featured.webp',
        pathname: 'moya',
        keywords: 'Customs optimization, Cloud-based platform, Import management, UX/UI design, Streamlined processes, Efficiency',
        element: (props) => <UxuiTemplate {...props} />,
        active: true,
        category: 'uxui'
    },
    {
        id: 2,
        name: 'Seven Peaks Website',
        desc: 'Advanced Seven Peaks website designed to enhance user experience and boost sales, featuring seamless HubSpot integration for effective marketing and sales management',
        featured: '/images/projects/sevenpeaks/featured.webp',
        pathname: 'sevenpeaks',
        keywords: 'Digital transformation, UX methodology, Business adaptation, Digital growth, Innovative solutions, User-Centric Design',
        element: (props) => <UxuiTemplate {...props} />,
        active: true,
        category: 'uxui'
    },
    {
        id: 7,
        name: 'Lumamate App',
        desc: 'Innovative app for managing a solar-powered portable charger, crafted to maximize efficiency and convenience for outdoor adventures',
        pathname: 'lumamate',
        featured: '/images/projects/lumamate/featured.webp',
        keywords: 'Portable Charger, Solar Technology, Interface Control, Outdoor Exploration, Intelligent Application, User-Centric Design',
        element: (props) => <UxuiTemplate {...props} />,
        active: true,
        category: 'uxui'
    },
    {
        id: 3,
        name: 'Arcadia Platform',
        desc: 'Blockchain-powered platform for classic games designed to improve user engagement and offer decentralized gaming experiences',
        pathname: 'arcadia',
        featured: '/images/projects/arcadia/featured.webp',
        keywords: 'Blockchain, Classic Games, Decentralized Gaming, User Engagement, Digital Currency, Gaming Platform',
        element: (props) => <UxuiTemplate {...props} />,
        active: true,
        category: 'uxui'
    },
    {
        id: 9,
        name: 'Kuadi Research',
        desc: 'Advanced analytics for efficient vehicle management, designed to boost operational effectiveness and streamline fleet operations',
        pathname: 'kuadi',
        featured: '/images/projects/kuadi/featured.webp',
        keywords: 'Vehicle Analytics, Delivery Optimization, User-Centric Design, Efficient Processes',
        element: (props) => <UxuiTemplate {...props} />,
        active: true,
        category: 'uxui'
    },
    {
        id: 4,
        name: 'Alkewallet App',
        desc: 'Comprehensive platform developed to streamline digital financial transactions and improve user efficiency',
        pathname: 'alkewallet',
        featured: '',
        keywords: 'Financial Management, Cryptocurrency Exchange, Secure Transactions, Money Transfers, User-Friendly Interface, Digital Wallet',
        element: (props) => <UxuiTemplate {...props} />,
        active: false,
        category: 'uxui'
    },
    {
        id: 5,
        name: 'Tikcoin App',
        desc: 'Intuitive digital wallet designed to simplify financial management and provide a seamless experience',
        pathname: 'tikcoin',
        featured: '',
        keywords: 'Digital Wallet, Cryptocurrency Management, Financial Security, Seamless Transactions',
        element: (props) => <UxuiTemplate {...props} />,
        active: false,
        category: 'uxui'
    },
    {
        id: 6,
        name: 'Eyed App',
        desc: 'Tourism app utilizing augmented reality to immerse users in travel experiences and enhance exploration',
        pathname: 'eyed',
        featured: '',
        keywords: 'Immersive Experience, AR Technology, Tourism Exploration, Augmented Reality, Travel Enhancement, User-Centric Design',
        element: (props) => <UxuiTemplate {...props} />,
        active: false,
        category: 'uxui'
    },
    {
        id: 8,
        name: 'Dmeal App',
        desc: 'Intuitive app designed to optimize macronutrient control and facilitate healthy diet management',
        pathname: 'dmeal',
        featured: '',
        keywords: 'Nutrition Optimization, Intuitive Control, Macronutrient Management, Health Tracking, Dietary Efficiency, Wellness Enhancement',
        element: (props) => <UxuiTemplate {...props} />,
        active: false,
        category: 'uxui'
    }
];
