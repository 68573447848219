import React from 'react';

export const CloseIcon = ({ className }) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke='currentColor'
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            data-icon="CloseIcon"
            aria-hidden="true"
        >
            <path d="M5.636 5.636l12.728 12.728m-12.728 0L18.364 5.636m0 0"></path>
        </svg>
    );
};
