import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeaderContent } from '@/components/HeaderContent';
import { Error404Icon } from '@/components/icons/Error404Icon';

export const ErrorPage = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderContent
                title={props.name}
                desc={props.desc}
                keywords={props.keywords}
                pathname={props.pathname}
            />
            <section className='container mx-auto max-w-screen-sm flex flex-col justify-center items-center w-full h-screen gap-6'>
                <Error404Icon width={56} height={56} />
                <h1 className='text-4xl text-center'>
                    {t('error.title')}
                </h1>
                <p className='text-base text-center'>
                    {t('error.desc')}
                </p>
                <Link to="/">
                    <p className='font-medium text-primary border border-primary/25 hover:brightness-125 rounded-xl px-5 py-3 hover:scale-110 transition-all duration-500'>
                        {t('error.button')}
                    </p>
                </Link>
            </section>
        </>
    );
};
