import { useEffect, useRef, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ThemeProvider } from "@/theme/ThemeProvider";
import { Settings } from "@/components/Settings";
import { Analytics } from "@vercel/analytics/react"

export function App() {

  const location = useLocation();
  const [prevPath, setPrevPath] = useState(location.pathname);
  const nodeRef = useRef(null);
  const currentPage = useOutlet();
  const [transitionClass, setTransitionClass] = useState("slide-right");

  useEffect(() => {
    if (location.pathname !== prevPath) {
      setTransitionClass(location.pathname < prevPath ? "slide-right" : "slide-left");
      setPrevPath(location.pathname);
    }
  }, [location.pathname, prevPath]);

  return (
    <ThemeProvider>
      <HelmetProvider>
        <Settings />
        <SwitchTransition>
          <CSSTransition
            key={location.key}
            timeout={600}
            classNames={transitionClass}
            nodeRef={nodeRef}
            unmountOnExit
          >
            <main ref={nodeRef}>
              {currentPage}
            </main>
          </CSSTransition>
        </SwitchTransition>
        <Analytics />
      </HelmetProvider>
    </ThemeProvider>
  );
}
