import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CardButton } from '@/components/CardButton';
import { PhotoErrorIcon } from '@/components/icons/PhotoErrorIcon';

const items = {
    open: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
    closed: {
        y: 40,
        scale: 0.9,
        opacity: 0,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
};

export const CardBlog = ({ id, pathname, featured }) => {
    const { t } = useTranslation();

    return (
        <motion.div
            variants={items}
            className="relative flex-shrink-0 w-full h-full flex-none aspect-square dark:bg-cinder bg-mischka/25 rounded-3xl cursor-pointer transition-all brightness-95 hover:brightness-100 duration-300 overflow-hidden selection"
        >
            <a
                href={pathname}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Go to ${id}-blog`}
                id={id}
            >
                {featured ? (
                    <img
                        className="w-auto h-full absolute object-cover"
                        src={featured}
                        alt={`${id}-blog`}
                    />
                ) : (
                    <div className="flex flex-col gap-4 items-center justify-center absolute inset-0 opacity-50">
                        <PhotoErrorIcon width={32} height={32} />
                        <p className="text-center text-lg font-medium">
                            {t('error.image')}
                        </p>
                    </div>
                )}
                <div className="relative w-full flex flex-col justify-between items-start p-9 h-full">
                    <div className="flex flex-col">
                        <p className="text-base font-medium text-alabaster">
                            {t(`blog.${id}.title`)}
                        </p>
                    </div>
                    <div className="self-end">
                        <CardButton />
                    </div>
                </div>
            </a>
        </motion.div>
    );
};
