import React from 'react';
import { motion } from 'framer-motion';
import { blogs } from '@/routes/blogs';
import { CardBlog } from '@/components/CardBlog';

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

export const GridBlog = () => {

    return (
        <section className="overflow-hidden relative">
            <motion.div
                variants={list}
                initial="closed"
                animate="open"
                className="flex flex-nowrap w-full md:grid md:grid-cols-3 md:gap-6"
            >
                {blogs.map(project => (
                    <CardBlog key={project.id} {...project} />
                ))}
            </motion.div>
        </section>
    );
};
