import React from 'react'
import ReactDOM from 'react-dom/client'

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enTranslation from '@/localization/en.json';
import esTranslation from '@/localization/es.json';
import thTranslation from '@/localization/th.json';
import jpTranslation from '@/localization/jp.json';


import './css/index.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes/router'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    es: { translation: esTranslation },
    th: { translation: thTranslation },
    jp: { translation: jpTranslation }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)
