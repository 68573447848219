import React from 'react';
import { ContactForm } from '@/components/ContactForm';
import { SocialNetworks } from '@/components/SocialNetworks';
import { useTranslation } from 'react-i18next';
import { HexagonIcon } from '@/components/icons/HexagonIcon';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer id='contact' className='dark:bg-cinder bg-mischka/25 rounded-3xl w-full max-w-screen-lg min-h-[28rem] mx-auto'>
            <div className='flex flex-col md:flex-row p-9 gap-24'>

                <div className='flex flex-col space-y-8 md:justify-between w-full md:w-1/2'>

                    <div className='space-y-20'>

                        <div className='space-y-4'>
                            <h2 className='text-4xl text-woodsmoke dark:text-alabaster font-medium leading-tight text-center md:text-left'>
                                {t(`footer.title`)}
                            </h2>
                            <p className='text-base text-center md:text-left opacity-80'>
                                {t(`footer.tagline`)}
                            </p>
                        </div>

                        <div className='flex items-center justify-center'>
                            <HexagonIcon className='text-primary float h-28' />
                        </div>

                    </div>

                    <SocialNetworks />
                </div>

                <ContactForm />

            </div>
        </footer>
    );
};
