import React from 'react';

export const HexagonIcon = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            viewBox="0 0 100 100"
            data-icon="HexagonIcon"
            aria-hidden="true"
        >
            <path d="M49.7852 74.0003V98M9.67903 26.0009L28.3962 39.3342M71.1741 39.3342L89.8922 28.6678M87.2177 22.5024C88.8566 23.4523 90.2144 24.8168 91.1535 26.4578C92.0925 28.0988 92.5794 29.9578 92.5649 31.8466V66.8055C92.5649 70.6877 90.4576 74.2662 87.0571 76.1543L54.9719 96.6554C53.3838 97.5348 51.5968 97.9964 49.7797 97.9964C47.9627 97.9964 46.1757 97.5348 44.5876 96.6554L12.5023 76.1543C10.8345 75.2241 9.44557 73.8681 8.47848 72.2258C7.51138 70.5834 7.00105 68.714 7 66.81V31.8475C7 27.9591 9.10734 24.3805 12.5023 22.4979L44.5876 3.38812C46.2217 2.47791 48.0626 2 49.9348 2C51.807 2 53.648 2.47791 55.2821 3.38812L87.3673 22.5024H87.2068H87.2177Z" />
            <path d="M49.7826 26V49.8395M49.7826 49.8395L28.913 60.4348M49.7826 49.8395L70.6522 60.4348" />
            <path d="M49.7826 74L70.6522 60.6666V39.3334L49.7826 26L28.913 39.3334V60.6666L49.7826 74Z" />
        </svg>
    );
};
