import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavigationButton } from '@/components/NavigationButton';
import { ChevronLeftIcon } from '@/components/icons/ChevronLeftIcon';
import { Footer } from '@/components/Footer';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { DiplomaIcon } from '@/components/icons/DiplomaIcon';
import { DegreeIcon } from '@/components/icons/DegreeIcon';

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

const items = {
    open: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
    closed: {
        y: 40,
        scale: 0.9,
        opacity: 0,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
};

export const ResumePage = () => {
    const { t } = useTranslation();
    const text = t('resume.introduction.desc');
    const paragraphs = text.split(/\n/);
    const formattedText = paragraphs.map(p => `<p>${p}</p>`).join('');

    const experiences = t('resume.experience.companies', { returnObjects: true });
    const educations = t('resume.education.schools', { returnObjects: true });

    const [visibleExperienceItems, setVisibleExperienceItems] = useState(3);
    const [visibleEducationItems, setVisibleEducationItems] = useState(3);

    const showMoreExperienceItems = () => setVisibleExperienceItems(prev => prev + 3);
    const showMoreEducationItems = () => setVisibleEducationItems(prev => prev + 3);

    return (
        <div className="container mx-auto flex h-full min-h-screen flex-col gap-6 p-4 max-w-screen-lg">

            {/* Header Section */}
            <header className='min-h-20 container mx-auto max-w-screen-lg flex flex-col p-4 sm:p-6 justify-center gap-6 dark:bg-cinder bg-mischka/25 rounded-3xl'>
                <nav className='flex w-full justify-between items-center'>
                    <span className='flex items-center gap-0 sm:gap-2'>
                        <Link to='/'>
                            <NavigationButton ariaLabel="Back to Home">
                                <ChevronLeftIcon />
                            </NavigationButton>
                        </Link>
                        <h4 className="text-lg font-medium text-woodsmoke dark:text-alabaster">
                            {t('navbar.resume')}
                        </h4>
                    </span>
                </nav>
            </header>

            {/* Introduction Section */}
            <section className="flex flex-col gap-3 md:flex-row">
                <div id="introduction" className="flex w-full rounded-3xl overflow-hidden dark:bg-cinder bg-mischka/25 md:w-1/3">
                    <img src='images/profile.webp' width="100%" height="100%" className='h-60 md:h-auto object-cover object-top w-full' alt='profile image' />
                </div>
                <div id="introduction" className=" rounded-3xl dark:bg-cinder bg-mischka/25 md:w-2/3 flex flex-col justify-between p-9 w-full h-auto'">
                    <div className='space-y-6'>
                        <h1 className='text-4xl text-woodsmoke dark:text-alabaster font-medium leading-tight'>
                            {t('resume.introduction.title')}
                        </h1>
                        <div className='text-base leading-relaxed opacity-80 flex flex-col gap-3' dangerouslySetInnerHTML={{ __html: formattedText }} />
                    </div>
                </div>
            </section>

            {/* Experience Section */}
            <div className="flex flex-col gap-3 md:gap-4">
                <section id="experience" className="flex w-full rounded-3xl dark:bg-cinder bg-mischka/25">
                    <div className="flex flex-col p-9 gap-8 w-full h-auto justify-between">
                        <h2 className="font-medium text-2xl">{t('resume.experience.title')}</h2>
                        <motion.div variants={list} initial="closed" animate="open" className="flex flex-col">
                            {experiences.slice(0, visibleExperienceItems).map((experience, index) => (
                                <motion.div key={index} variants={items}>
                                    <div className="flex flex-col gap-3">
                                        <div className="leading-normal">
                                            <h3 className="font-semibold">{experience.role}</h3>
                                            <h4 className="opacity-80">{experience.company}</h4>
                                            <p className="text-sm opacity-80">{experience.date}</p>
                                            <p className="text-sm opacity-80">{experience.country}</p>
                                        </div>
                                        {/* <p className="text-sm">{experience.description}</p> */}
                                        {/* <div className="flex flex-wrap gap-2">
                                            {experience.keywords.map((keyword, keywordIndex) => (
                                                <span key={keywordIndex} className="px-4 py-1 text-xs rounded-full border border-shark/10 bg-shark/10 dark:bg-mischka/10 max-w-fit">
                                                    {keyword}
                                                </span>
                                            ))}
                                        </div> */}
                                    </div>
                                    {index < visibleExperienceItems - 1 && index < experiences.length - 1 && <hr className="my-6 border-mischka/60 dark:border-mischka/10" />}
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>
                {visibleExperienceItems < experiences.length && (
                    <button onClick={showMoreExperienceItems} aria-label="Show More Experience"
                        className="flex items-center justify-center w-full rounded-xl border-2 min-h-12 py-3 transition-all duration-500 hover:bg-primary hover:text-alabaster border-mischka/25 dark:border-cinder dark:text-alabaster hover:border-primary">
                        <p className="text-sm font-medium">{t('resume.experience.button')}</p>
                    </button>
                )}
            </div>

            {/* Education Section */}
            <div className="flex flex-col gap-3 md:gap-4">
                <section id="skills" className="flex w-full rounded-3xl dark:bg-cinder bg-mischka/25">
                    <div className="flex flex-col p-9 gap-8 w-full h-auto justify-between">
                        <h2 className="text-2xl font-medium">{t('resume.education.title')}</h2>
                        <motion.div variants={list} initial="closed" animate="open" className="flex flex-col">
                            {educations.slice(0, visibleEducationItems).map((education, index) => (
                                <motion.div key={index} variants={items}>
                                    <div className="flex flex-col md:flex-row gap-4">
                                        <span>
                                            {education.type === "diploma" ? <DiplomaIcon /> : <DegreeIcon />}
                                        </span>
                                        <div className="w-full leading-normal">
                                            <h3 className="font-semibold">{education.major}</h3>
                                            <h4 className="opacity-80">{education.school}</h4>
                                            <p className="text-sm opacity-80">{education.date}</p>
                                            <p className="text-sm opacity-80">{education.country}</p>
                                        </div>
                                    </div>
                                    {index < visibleEducationItems - 1 && index < educations.length - 1 && <hr className="my-6 border-mischka/60 dark:border-mischka/10" />}
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>
                {visibleEducationItems < educations.length && (
                    <button onClick={showMoreEducationItems} aria-label="Show More Education"
                        className="flex items-center justify-center w-full rounded-xl border-2 min-h-12 py-3 transition-all duration-500 hover:bg-primary hover:text-alabaster border-mischka/25 dark:border-cinder dark:text-alabaster hover:border-primary">
                        <p className="text-sm font-medium">{t('resume.education.button')}</p>
                    </button>
                )}
            </div>

            {/* Footer Section */}
            <Footer />
        </div>
    );
};
