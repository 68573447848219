import React from 'react';
import { SwitchTheme } from '@/components/SwitchTheme';
import { SwitchLanguage } from '@/components/SwitchLanguage';

export const Settings = () => {
    return (
        <div className="fixed z-50 flex flex-col gap-2 p-2 end-0">
            <SwitchLanguage />
            <SwitchTheme />
        </div>
    );
};
