import { uxuiProjects } from '@/routes/uxuiProjects';
import { devProjects } from '@/routes/devProjects';
import { brandingProjects } from '@/routes/brandingProjects';
import { productProjects } from '@/routes/productProjects';
import { otherProjects } from '@/routes/otherProjects';

export const allProjects = {
    "uxui": uxuiProjects,
    "dev": devProjects,
    "branding": brandingProjects,
    "product": productProjects,
    "other": otherProjects
};
