import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export const HeaderContent = ({ title, desc, keywords }) => {
    const location = useLocation();
    const canonicalUrl = `https://zanozano.dev${location.pathname}`;

    return (
        <Helmet>
            <title>{`${title} - Creative UI/UX Design and Full Stack Development Solutions`}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};
