import { GridProject } from "@/components/GridProject"
import { Profile } from "@/components/Profile"
import { Footer } from "@/components/Footer"
import { Navbar } from "@/components/Navbar"
import { HeaderContent } from "@/components/HeaderContent"
import { GridBlog } from "@/components/GridBlog"

export function HomePage(props) {

    return (
        <>
            <HeaderContent
                title={props.name}
                desc={props.desc}
                keywords={props.keywords}
                pathname={props.pathname}
            />
            <div className="container mx-auto max-w-screen-lg flex flex-col gap-6 p-4">
                <Navbar />
                <Profile />
                <GridProject />
                <GridBlog />
                <Footer />
            </div>
        </>
    )
}
