import React, { useState } from 'react';
import { CardProject } from '@/components/CardProject';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { uxuiProjects } from '@/routes/uxuiProjects';

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

export const GridProject = () => {
    const { t } = useTranslation();
    const [visibleCount, setVisibleCount] = useState(6);

    const loadMoreProjects = () => {
        setVisibleCount(prevCount => prevCount + 2);
    };

    const displayedProjects = uxuiProjects.slice(0, visibleCount);

    return (
        <main>
            <section className='flex flex-col gap-3 md:gap-4'>
                <motion.div
                    variants={list}
                    initial="closed"
                    animate="open"
                    className='grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4'
                >
                    {displayedProjects.map(project => (
                        <CardProject key={project.id} {...project} />
                    ))}
                </motion.div>

                {visibleCount < uxuiProjects.length && (
                    <button
                        onClick={loadMoreProjects}
                        aria-label='Show More Projects'
                        className='border-2 hover:border-primary border-mischka/25 dark:border-cinder min-h-12 py-3 dark:text-alabaster hover:bg-primary hover:text-alabaster rounded-xl w-full transition-all duration-500 overflow-hidden flex justify-center items-center'
                    >
                        <p className='text-sm font-medium'>
                            {t(`others.showMore`)}
                        </p>
                    </button>
                )}
            </section>
        </main>
    );
};
