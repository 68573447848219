export const blogs = [
    {
        id: 1,
        pathname: 'https://medium.com/@zanozanozano/figma-2024-integrated-ai-efficient-design-and-impactful-presentations-044a3b488784',
        featured: './images/blogs/blog-1.webp',
    },
    {
        id: 2,
        pathname: 'https://medium.com/@zanozanozano/definitive-guide-to-creating-an-effective-prompt-in-firefly-3-9f4229efac20',
        featured: './images/blogs/blog-2.webp',

    },
    {
        id: 3,
        pathname: 'https://medium.com/@zanozanozano/using-variables-in-figma-to-control-dynamic-states-7affccf0e771',
        featured: './images/blogs/blog-3.webp',
    }
]
