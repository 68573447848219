import React from 'react';

export const CalendarIcon = ({ className }) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke='currentColor'
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            data-icon="CloseIcon"
            aria-hidden="true"
        >
            <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5" /><path d="M16 3v4" /><path d="M8 3v4" /><path d="M4 11h16" /><path d="M16 19h6" /><path d="M19 16v6" />
        </svg>
    );
};
