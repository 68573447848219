import React from 'react';

export const GoalIcon = ({ width = 24, height = 24, color = 'currentColor', strokeWidth = 1.5, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M12 7a5 5 0 1 0 5 5" />
            <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
            <path d="M15 6v3h3l3 -3h-3v-3z" />
            <path d="M15 9l-3 3" />
        </svg>
    );
};
