import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

export const useForm = () => {
    const { t } = useTranslation();

    const initialValues = {
        name: '',
        email: '',
        message: ''
    };

    const initialErrors = {
        name: '',
        email: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialValues);
    const [errors, setErrors] = useState(initialErrors);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = t('nameError');
        if (!formData.email) newErrors.email = t('emailError');
        if (!formData.message) newErrors.message = t('messageError');
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true);
            try {
                const response = await fetch('/api/sendEmail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: t('contact.messageSuccess'),
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: {
                            popup: 'bg-alabaster text-shark dark:bg-woodsmoke dark:text-mischka rounded-3xl shadow-lg'
                        }
                    });
                    setFormData(initialValues);
                } else {
                    throw new Error('Failed to submit form');
                }
            } catch (error) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: t('contact.messageUnsuccess'),
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        popup: 'bg-alabaster text-shark dark:bg-woodsmoke dark:text-mischka rounded-3xl shadow-lg'
                    }
                });
            } finally {
                setIsSubmitting(false);
            }
        } else {
            Swal.fire({
                position: "center",
                icon: "error",
                title: t('contact.formIncomplete'),
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    popup: 'bg-alabaster text-shark dark:bg-woodsmoke dark:text-mischka rounded-3xl shadow-lg'
                }
            });
        }
    };

    return {
        formData,
        errors,
        handleInputChange,
        handleFormSubmit,
        isSubmitting
    };
};
