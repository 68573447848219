import React from 'react';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';

export const CardButton = () => {
    return (
        <button
            className="chevron-button p-3 bg-alabaster rounded-full flex items-center justify-center transition-all duration-300"
            aria-label="Go to the Project"
        >
            <ChevronRightIcon className="text-shark" />
        </button>
    );
};
