import React from 'react';
import moonIcon from '@/assets/moon.svg';
import sunIcon from '@/assets/sun.svg';
import { useTheme } from '@/theme/ThemeProvider';
import { ActionButton } from '@/components/icons/ActionButton';

export const SwitchTheme = () => {
    const { theme, toggleTheme } = useTheme();

    const isDarkTheme = theme === 'dark';
    const iconSrc = isDarkTheme ? sunIcon : moonIcon;
    const iconAlt = isDarkTheme ? 'Moon icon' : 'Sun icon';
    const ariaLabel = `Toggle ${isDarkTheme ? 'light' : 'dark'} theme`;

    return (
        <ActionButton onClick={toggleTheme} ariaLabel={ariaLabel}>
            <img
                width='24px'
                height='24px'
                src={iconSrc}
                alt={iconAlt}
                className="transition-transform duration-300 transform rotate-0 dark:rotate-180"
            />
        </ActionButton>
    );
};
