import React from 'react';

export const DribbbleIcon = ({ className }) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            data-icon="DribbbleIcon"
            aria-hidden="true"
        >
            <path d="M0 0h24v24H0z" stroke="none" /><path d="M3 12a9 9 0 1 0 18 0 9 9 0 1 0-18 0" /><path d="M9 3.6c5 6 7 10.5 7.5 16.2" /><path d="M6.4 19c3.5-3.5 6-6.5 14.5-6.4M3.1 10.75c5 0 9.814-.38 15.314-5" />
        </svg>
    );
};
